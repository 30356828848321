import React from "react";

const SearchHeader = ({
  heading,
  onClickHandler,
  toggleAuditHandler,
  arrayWithCientData,
  moveToClient,
}) => {
  return (
    <div className="header">
      <div className="cardheading">
        <h2>{heading}</h2>
      </div>

      <div className="form-group   floatright margintop-5">
        {toggleAuditHandler && (
          <button
            id="viewAuditBtn"
            name="viewAuditBtn"
            className="btn btn-warning"
            onClick={() => toggleAuditHandler("", "Deleted")}
          >
            View Audit
          </button>
        )}
        {onClickHandler && (
          <button
            id="addNewBtn"
            type="button"
            className="btn btn-primary ml-1"
            onClick={() => onClickHandler(true, null)}
          >
            Add New
          </button>
        )}
      </div>
      {arrayWithCientData?.length > 0 ? (
        <button
          id="addNewBtn"
          name="addNewBtn"
          className="btn btn-primary floatright margintop-5 mr-2"
          onClick={() => moveToClient()}
        >
          Move to Client
        </button>
      ) : null}
    </div>
  );
};
export default SearchHeader;
